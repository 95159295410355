<template>
  <div class="ecommerce-application">
    <b-overlay :show="show" rounded="sm">
    <div class="list-view product-checkout mt-0">
      <div class="checkout-items">
        <b-card v-for="structure,index in structures" :key="index" class="ecommerce-card" no-body>
          <div class="item-img">
              <EstructuraCanvas :structure="structure" :dimensions="{width : structure.frame_width , heigh: structure.frame_height}" />
          </div>
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-1">{{structure.name}} ( {{structure.frame_width}} x {{structure.frame_height}})</h6>
              <ul>
              <div v-for="vdiv in structure.v_divisions" :key="vdiv.id">
              <div  v-for="hdiv in vdiv.h_divisions" :key="hdiv.id" >
                <li v-if="hdiv.element.name != 'Pared'">{{hdiv.element.name}}</li>
              </div>
              </div>
              </ul>
            </div>
          </b-card-body>
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-quantity">
                <span class="quantity-title">Unidades:</span>
                <b-form-spinbutton v-model="structure.qty" size="sm" class="ml-75" inline  :disabled="order.state > 0" @change="edit++"/>
              </div>
            </div>
            <b-button variant="light" class="mt-1 remove-wishlist" :disabled="order.state > 0" @click="removeStructure(index)">
              <feather-icon icon="XIcon" class="mr-50" />
              <span>Eliminar</span>
            </b-button>
          </div>
        </b-card>
      </div>
      <div class="checkout-options">
        <b-card class="position-relative">
            <h5>OPCIONES</h5>
            <b-badge pill :variant="!order.state ? status[0].color : status[order.state].color " class="p-1 position-absolute status badge-glow">
              {{ !order.state ? status[0].title : status[order.state].title }} 
            </b-badge>
            <b-button v-if="order.state == 1" link variant="success" block @click="input_accept = !input_accept" >Pedido Fabricación</b-button>
            <div  v-if="input_accept">
              <b-input-group class="my-1">
                <b-form-input
                  type="text"
                  v-model="input_ref"
                  class="form-control-merge text-center"
                  placeholder="nº referencia"
                />
                <b-input-group-append>
                  <b-button variant="outline-success" @click="accept(order.index)" :disabled="!input_ref">OK</b-button>
                </b-input-group-append>
              </b-input-group>
              <b-alert variant="dark" show >
                 <div class="alert-body">
                  <small> Debe introducir el número del presupuesto enviado por el fabricante.</small>
                </div>
              </b-alert>
            </div>
            <b-button v-if="order.state == 1" variant="warning" block @click="reOrder()">Modificar Pedido</b-button>
            <b-button v-if="order.state == 1" variant="danger" block @click="cancel(order.index)">Eliminar Pedido Fabricación</b-button>
            <b-button v-if="order.state == 0" variant="danger" block @click="cancel(order.index)">Eliminar Solicitud Presupuesto</b-button>
            <b-button variant="secondary" block @click="print">
              Imprimir Pedido
            </b-button>
            <b-button  v-if="order.state == 0" variant="success" block @click="send()">
              Solicitar Presupuesto Fabricante
            </b-button>                  
          <hr>
          <div class="text-muted w-100 text-center"  v-if="order.state == 2 && order.costumer_budget">
            Referencia pedido : {{order.costumer_budget}} 
            <hr>                
          </div>
          <div class="price-details">
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="col">
                  Fabricante
                </div>
                <div class="col-8">
                  <v-select v-model="order.fabricante" label="title" :options="fabricante_options" :disabled="order.state > 0"/>
                </div>
              </li>
              <li class="price-detail">
                <div class="col">
                  Fecha
                </div>
                <div class="col">
                  <input class="form-control" type="date" v-model="order.fecha" :readOnly="order.state > 0"/>
                </div>
              </li>
              <li class="price-detail">
                <div class="col">
                  Referencia presupuesto
                </div>
                <div class="col">
                  <input class="form-control text-right" type="text" v-model="order.referencia" :readOnly="order.state > 0">
                </div>
              </li>
            </ul>
            <hr>
            <div v-if="order.state < 1">
              <b-button variant="success" block @click="next(0)">
                Guardar Cambios
              </b-button>
              <b-button variant="warning" @click="next(1)" block>
                Añadir Estructura
              </b-button>
            </div>
          </div>

        </b-card>
      </div>
    </div>
    </b-overlay>
  </div>
</template>

<script>

import { BCard, BBadge, BFormDatepicker, BOverlay ,BCardBody, BLink, BButton, BAlert, BFormSpinbutton, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import EstructuraCanvas from './EstructuraCanvas.vue'
import vSelect from "vue-select";
import { mapState } from 'vuex';

export default {
  components: {
    BCard, BBadge, BCardBody, BLink, BButton, BFormDatepicker, BOverlay, BFormSpinbutton, BAlert, BFormInput, BInputGroup, BInputGroupAppend,  EstructuraCanvas, vSelect
  },
  data() {
    return {
      show : false,
      edit: false,
      new : false,
      order: {
        id: null,
        fecha: null,
        referencia: null,
        fabricante: null,
        state : 0
      },
      status : [
        {title : 'Nuevo', color :  'secondary' },
        {title : 'Solicitado', color :  'warning' },
        {title : 'Aceptado', color : 'success'},
        {title : 'Cancelado', color : 'danger'}
        ],      
      structures: [],
      fabricante_options: [],
      input_accept : false,
      input_ref : null,
      open : false
    }
  },
  mounted() {
    this.new = this.$route.params.new ? true : false;
    this.$http.get("/api/getManufacturers").then((response) => {
      this.fabricante_options = response.data
    });
    if (!this.new) {
      this.order = this.full_state.order;
    }else{
      this.$store.commit('ventanas/setOrder', this.order)
    }
    if (this.order.id) {
       this.$http.get("/api/getStructures?id=" + this.order.id).then((response) => {
        this.structures = response.data
       });
    }
  },
  methods: {
    removeStructure(index) {
      this.$bvModal.msgBoxConfirm('¿Quiere eliminar la estructura del pedido?',{okVariant: 'success', size: 'sm', buttonSize: 'sm', okTitle: 'SI', cancelTitle: 'NO'})
        .then(value => {
            if(value) {
              this.$http.post("/api/deleteStructure",{id : this.structures[index].id}).then((response) => {
                if (response.data) {
                  this.structures.splice(index, 1);
                   this.$bvToast.toast('Elemento Eliminado', { variant: 'success', title: 'Confirmación' });
                }else {
                  alert('El cambio no ha sido registrado')
                }
              });   
            }
          })
          .catch(err => {
            // An error occurred
          })              
    },
    validate() {
      if (this.order.fabricante == null) {
        this.$bvToast.toast('Debe introducir un fabricante', { variant: 'danger', title: 'Error' });
        return 0;
      }
      if (this.order.fecha == null) {
        this.$bvToast.toast('Debe introducir un fecha', { variant: 'danger', title: 'Error' });
        return 0;
      }
      if (this.order.referencia == null) {
        this.$bvToast.toast('Debe introducir una referencia', { variant: 'danger', title: 'Error' });
        return 0;
      }
      return 1;
    },
    next(from) {
      if (this.validate()) {
        this.show = true;
        this.$http.post("/api/InsertOrder", { user: this.user.id, order: this.order, structures : this.structures }).then((response) => {
          if (response.data) {
            this.order.id = response.data
            this.$store.commit('ventanas/setOrder', this.order)
            if (from) {
              this.$router.push("/create");
            }else{
              this.$bvToast.toast('Pedido Guardado', { variant: 'success', title: '' });
            }
          } else {
            this.$bvToast.toast('Los datos no han podido ser almacenados', { variant: 'danger', title: 'Error' });
          }
          this.show = false;
        });
      }
    },
    accept(index){
      this.$bvModal.msgBoxConfirm('¿Quiere tramitar el pedido?',{okVariant: 'success', size: 'sm', buttonSize: 'sm', okTitle: 'SI', cancelTitle: 'NO'})
          .then(value => {
            if(value) {
              this.show = true;
              this.$http.post("/api/AcceptOrder",{order : this.order.id, reference : this.input_ref}).then((response) => {
                if (response.data) {
                  this.order.state = 2;
                  this.order.costumer_budget = this.input_ref;
                  this.$bvToast.toast('La confirmación ha sido enviada al fabricante correctamente', { variant: 'success', title: 'Aceptado' });
                }else {
                 this.$bvToast.toast('No se ha podido aceptar el pedido', { variant: 'danger', title: 'Error' });
                }
                this.show = false;
              });      
            }
          })
          .catch(err => {
            // An error occurred
          })
      this.input_accept = false  
      this.open = false;
    },
    cancel(index) {
      this.$bvModal.msgBoxConfirm('¿Quiere cancelar el pedido?',{size: 'sm', buttonSize: 'sm', okTitle: 'SI', cancelTitle: 'NO'})
          .then(value => {
            if(value) {
              this.show = true;
              this.$http.post("/api/CancelOrder",{order : this.order.id}).then((response) => {
                if (response.data) {
                  this.order.state = 3
                }else {
                  alert('El cambio no ha sido registrado')
                }
                this.show = false;
              });      
            }
          })
          .catch(err => {
            // An error occurred
          })

      this.input_accept = false
      this.input_ref = null      
      this.open = false;
    },
    reOrder() {
      this.$bvModal.msgBoxConfirm('¿Quiere editar y volver a pedir presupuesto?',{size: 'sm', buttonSize: 'sm', okTitle: 'SI', cancelTitle: 'NO'})
          .then(value => {
            if(value) {
              this.show = true;
              this.$http.post("/api/reOrder",{order : this.order.id}).then((response) => {
                if (response.data) {
                  this.order.state = 0
                }else {
                  alert('El cambio no ha sido registrado')
                }
                this.show = false;
              });      
            }
          })
          .catch(err => {
            // An error occurred
          })

    },
    send() {
      if(this.edit) {
        this.$bvToast.toast('Tiene cambios sin guardar. Guarde los cambios antes de enviar el pedido', { variant: 'warning', title: 'Aviso' });
        return 0    
      }else {
        this.show = true;
        this.$http.post("/api/BudgetRequest",{user : this.user.id,order : this.order, structures : this.structures}).then((response) => {
          if (response.data) {
            this.order.state = 1;
          }else {
            alert('Los datos no se han podido enviar')
          }
            this.show = false;
        });           
      }
    },
    print() {
      if(this.edit) {
        this.$bvToast.toast('Tiene cambios sin guardar. Guarde los cambios antes de imprimir el pedido', { variant: 'warning', title: 'Aviso' });
        return
      }
      this.show = true;
      let postConfig = {headers: {'X-Requested-With': 'XMLHttpRequest'},responseType: 'arraybuffer'} 
      this.$http.post("/api/PrintOrder",{user : this.user.id,order : this.order, structures : this.structures},postConfig).then((response) => {
        if (response.data) {
            var newBlob = new Blob([response.data], {type: 'application/pdf'})
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            if (this.order.state !== 2) {
              link.download = 'presupuesto_'+ this.order.referencia +'.pdf'              
            } else {
              link.download = 'pedido_'+ this.order.referencia +'.pdf'              
            }
            link.click()
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data)
            }, 100)
        }else {
          alert('Los datos no han podido ser almacenados')
        }
        this.show = false;
      });       
    },    
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      full_state: state => state.ventanas
    }),
  }
}
</script>

<style lang="scss" >
  @import '~@core/scss/base/pages/app-ecommerce.scss';
  @import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
.status {
  top: -1rem;
  right : -1rem;
}
</style>